<template>
   <div class="calendarBox">
      <!-- 筛选 -->
      <div class="roomType-screenBox" v-if="screenBool">
         <ul class="header border-b">
            <li>筛选</li><li class="cursor-p" @click="screenSwitch">x</li>
         </ul>
         <div class="main">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="checkedRoomType" @change="handleCheckedCitiesChange">
               <el-checkbox v-for="(item,index) in roomTypeTree" :label="item" :key="index">{{Maps.get(item)}}</el-checkbox>
            </el-checkbox-group>
         </div>
         <div class="footer border-t">
            <el-button size="mini" @click="checkedRoomType = []">重置</el-button>
            <el-button class="bg-gradient m-right-10" type="primary" size="mini" @click="confirmCheck">确认</el-button>
         </div>
      </div>
      <!-- table  -->
      <el-table :data="tableData" border fit style="margin-bottom: 20px;" :max-height="maxHeight"
                :style="{width:`${ screenBool ? '85%' : '100%' }`}"
                :header-cell-style="{ background: 'white',textAlign: 'center',}"
                :cell-style="{padding:'0px',paddingLeft:'0px',paddingRight:'0px',}"
                @cell-mouse-enter="(row, column, cell, event)=>{ mouseEnter(row,column,cell,event) }"
                @cell-mouse-leave="()=>{ mouseLeave() } " :cell-class-name="getCellClass"
                @cell-click="(row, column , cell)=>{ handleSelectCol(row,column,cell) }">
         <el-table-column align="center" width="270" fixed>
            <template slot="header">
               <div class="dateRangeBox" >
                  <div class="text-center">
                     <el-date-picker class="width-137 dayTimebox" size="mini" value-format="yyyy-MM-dd" v-model="beginTime" type="date" :editable="false" :clearable="false"
                                     :disabled="notAllowed" placeholder="选择日期" @change="handleBeginTime"></el-date-picker>
                     <span class="butToday " :class="[notAllowed ? 'cursor-n' : 'cursor-p']" @click="handleToday">今</span>
                  </div>
                  <div class="text-center grey">
                     <span class="date-btn front cursor-p" :class="[notAllowed ? 'cursor-n' : 'cursor-p']" @click="notAllowed ? '' : changeDate('-')"> <i class="el-icon-arrow-left"></i> </span>
                     <span>{{ dateRange }}</span>
                     <span class="date-btn queen cursor-p" :class="[notAllowed ? 'cursor-n' : 'cursor-p']" @click="notAllowed ? '' : changeDate('+')"> <i class="el-icon-arrow-right"></i> </span>
                  </div>
               </div>
               <el-row class="switchBox" >
                  <el-col :span="24" class="border-b border-l m-top-5" :class="notAllowed ? 'cursor-n': 'cursor-p'" @click.native="notAllowed ? '' : screenSwitch('open')" >筛选<i class="el-icon-search m-left-5"></i></el-col>
                  <!--                  <el-col :span="12" style="border-left: 1px solid #EBEEF5">2</el-col>-->
               </el-row>
            </template>
            <template slot-scope="props">
               <div style="display: flex;">
                  <div style="width: 50%;">
                     <div v-if="props.row.Bool" :style="{position:'absolute',zIndex:2,height:`${ 68 * props.row.roomTotalNum + (props.row.roomTotalNum - 1) }px`,
                     lineHeight:`${ 68 * props.row.roomTotalNum + 2}px`,background: 'white',width:'50%'}">
                        {{props.row.roomTypeName}}
                     </div>
                  </div>
                  <div style="width: 50%;">
                     <div style="height: 68px;line-height: 68px;border-left: 1px solid #EBEEF5;"
                          :class="[props.row.state === 'NOT_CLEAN_ROOM' ? 'cleanRoom' : '' ,notAllowed ? 'cursor-n' : 'cursor-p']"
                          @click=" notAllowed ? '' : handleSelectRoom(props.row)"
                          @mouseenter="notAllowed ? '' : mouseenterRoom($event,props.row)"
                          @mouseleave="notAllowed ? '' : mouseleaveRoom($event,props.row)">
                        {{props.row.roomNo}}
                     </div>
                  </div>
               </div>
            </template>
         </el-table-column>

         <el-table-column v-for="(items,index) in header" :key="index" align="center"
                          :label="`${!items.hasOwnProperty('dateString') ? '' : items.dateStr } ${items.week}`"
                          :label-class-name="`${items.dateStr == '今天' ? 'blue' : items.week == '五' ? 'red' : items.week == '六' ? 'red' : '' }`" >
            <el-table-column :prop="items.dateStr" :label="`剩${items.roomStatus.roomQuantity}间`" align="center" width="100" >
               <template v-slot="scope">
                  <!-- 悬停显示 mouseEnter mouseLeave  -->
                  <div v-if="mouseenterColBool && mouseenterColBoolKey === scope.row.id+'_'+items.dateString && !MapArr.includes(scope.row.id+'_'+items.dateString)"
                       class="mouseenter-box cursor-p">
                     <div>{{scope.row.roomTypeName}}</div>
                     <div>{{scope.row.roomNo}}</div>
                     <div>￥{{scope.row.roomPriceList[index].roomPrice}}</div>
                  </div>
                  <!-- 点击选中显示 handleSelectCol -->
                  <div v-show="arrkeys.includes(scope.row.id+'_'+items.dateString)" :class="[activeNameBool ? 'selectCol-box' : '',notAllowed ? 'cursor-n' : 'cursor-p']"></div>

                  <!-- 已有订单状态 v-if="reserveMap[]" -->
                  <el-popover v-if="reserveMap[`${scope.row.id}_${items.dateString}`]" placement="right" width="260" trigger="hover" :close-delay="100"
                              :disabled="notAllowed">
                     <div slot="reference"
                          class="orderState-box" :style="{width: `calc(${ 100 * reserveMap[`${scope.row.id}_${items.dateString}`].days + (reserveMap[`${scope.row.id}_${items.dateString}`].days - 1) }% - 4px)`,fontSize:'13px'}"
                          :class="[reserveMap[`${scope.row.id}_${items.dateString}`].checkInState === 'NOT_CHECKIN' ? 'orderState-or' :
                             reserveMap[`${scope.row.id}_${items.dateString}`].checkInState === 'CHECKIN' ? 'orderState-bl' :
                             reserveMap[`${scope.row.id}_${items.dateString}`].checkInState === 'CHECKOUT' ? 'orderState-gr' : '',
                             notAllowed ? 'cursor-n' : 'cursor-p']"
                          @click="notAllowed ? '' : orderDetails(reserveMap[`${scope.row.id}_${items.dateString}`],)">
                        <div style="display: flex;justify-content: space-between;margin: 5px 0;">
                           <div style="width:calc( 100% - 36px ); overflow: hidden; white-space: nowrap;text-overflow: ellipsis;margin-left: 2px;"> {{ reserveMap[`${scope.row.id}_${items.dateString}`].realName }}</div>
                           <div style="display: flex;">
                              <!-- 钟点房 -->
                              <div v-if="reserveMap[`${scope.row.id}_${items.dateString}`].hasOwnProperty('checkInType') && reserveMap[`${scope.row.id}_${items.dateString}`].checkInType === 'HOUR_ROOM'" class="arrearsTime"></div>
                              <!-- 是否欠款 -->
                              <div v-if="reserveMap[`${scope.row.id}_${items.dateString}`].amountsPayable > 0" class="arrears"></div>
                           </div>
                        </div>
                        <div style="height: 30px;line-height: 30px" >
                           <span style="display: inline-block;margin:0 2px 0 5px; border: 3px solid black;border-radius: 3px"></span>
                           {{reserveMap[`${scope.row.id}_${items.dateString}`].channel | filterChannel(that)}}
                        </div>
                     </div>
                     <!-- 悬停显示 有状态信息 -->
                     <el-scrollbar style="height: 100%">
                        <!-- 悬停显示 订单信息卡片 -->
                        <div class="popver">
                           <div v-for="(itemR,indexR) in reserveMap[`${scope.row.id}_${items.dateString}`].reserveList" :key="indexR">
                              <el-divider v-if="indexR !== 0"></el-divider>
                              <div class="popver-item">
                                 <span class="el-icon-postcard m-right-10"></span>
                                 <span class="m-right-10">{{ reserveMap[`${scope.row.id}_${items.dateString}`].realName }}</span>
                                 <span>{{ itemR.hasOwnProperty('bookInfo') ? itemR.bookInfo.mobile : '' }}</span>
                                 <el-tag style="margin-left:auto" size="mini" type="warning"> {{ itemR.checkInState | filterCheckInState}}</el-tag>
                              </div>
                              <div>
                                 <div><span class="m-right-10 el-icon-date"></span>{{ itemR.checkInTime || itemR.startTime}} 入住 | </div>
                                 <div style="margin-left: 24px;">{{ itemR.endTime }} 离店 | 共{{ itemR.durationName }}</div>
                              </div>
                              <div class="popver-item p-bottom-10" :class="{'border-b-dashed': indexR === 0}">
                                 <span class="m-right-10 el-icon-paperclip"></span>
                                 <span @click="orderDetails(itemR,true)" style="color: #0c7ffd;cursor:pointer;margin-bottom: 2px;">
                                    <span style="display: inline-block;border: 2px solid #0c7ffd;border-radius: 2px;margin-bottom: 3px;"></span>
                                    {{ itemR.bookInfo.channel | filterChannel(that) }}
                                 </span>
                              </div>
                              <div class="border-b-dashed p-top-10 p-bottom-10" v-if="indexR === 0" style="display: flex;justify-content: space-between;">
                                 <span>订单总额：￥{{ itemR.bookInfo.orderTotalPrice }}</span>
                                 <span>已收款：￥{{itemR.bookInfo.actualAmount}}</span>
                              </div>
                              <div class="p-top-10 p-bottom-10" v-if="indexR === 0"> 备注：{{itemR.bookInfo.remark || '无'}} </div>
                           </div>
                        </div>
                     </el-scrollbar>
                  </el-popover>

                  <!-- 已有关房状态 v-if="roomStateMap[]" -->
                  <el-popover :class="[notAllowed ? 'cursor-n' : 'cursor-p']" v-if="roomStateMap[`${scope.row.id}_${items.dateString}`]" placement="right" width="260" trigger="hover" :close-delay="100"
                              :disabled="notAllowed ? '' : cancelCloseroomsArr.includes(`${scope.row.id}_${items.dateString}`) ? true : false">
                     <div slot="reference" class="closeRooms-box" @click="notAllowed ? '' : closeroomsDetails(`${scope.row.id}_${items.dateString}`,roomStateMap[`${scope.row.id}_${items.dateString}`])">
                        <div style="margin-top: 37px">关房</div>
                     </div>
                     <div class="popver m-top-10 m-bottom-10">
                        <div>备注：{{roomStateMap[`${scope.row.id}_${items.dateString}`].remark || '无'}}</div>
                        <el-divider></el-divider>
                        <div>操作时间：{{ roomStateMap[`${scope.row.id}_${items.dateString}`].createTime }}</div>
                        <div>操作人：{{ roomStateMap[`${scope.row.id}_${items.dateString}`].operatorName }}</div>
                     </div>
                  </el-popover>
               </template>
            </el-table-column>

         </el-table-column>
      </el-table>

      <!-- 点击显示 menu -->
      <vue-context-menu
            :contextMenuData="contextMenuData" class="context-menu"
            @AddroomMenu="AddroomMenu('NOT_CHECKIN')" @InroomMenu="AddroomMenu('CHECKIN')"
            @CloseroomMenu="CloseroomMenu" @OpenroomMenu="OpenroomMenu" @CancelroomMenu="CancelroomMenu">
      </vue-context-menu>
      <!-- 预订 抽屉   @selectCol="selectCol" -->
      <add-atrialstate :drawerBool="drawerBool" :currentData="currentData" :checkInState="checkInState"
                       @closeDrawer="closeDrawer" @spliceCol="spliceCol" @Refresh="getRoomCalendars" @setArrkeys="setArrkeys" @changeNotAllowed="changeNotAllowed"></add-atrialstate>
      <!-- 订单详情 抽屉 -->
      <order-details name="OrderDetails" :orderDetailsBool="orderDetailsBool" :bookId="bookId" :currentId="currentId"
                     @closeOrderDetails="closeOrderDetails" @getRoomCalendars="getRoomCalendars" @changeNotAllowed="changeNotAllowed"></order-details>

      <!-- 关房 -->
      <el-dialog title="关房" :visible.sync="closeroomBool" width="500px"  :before-close="closeroom" v-if="closeroomBool">
         <el-row>
            <el-col :span="2">备注</el-col>
            <el-col :span="21">
               <el-input class="m-bottom-20" type="textarea"  v-model="closeroomRemark" maxlength="200" rows="4" show-word-limit clearable></el-input>
            </el-col>
         </el-row>
         <span slot="footer" class="dialog-footer">
              <el-button @click="closeroom">取消</el-button>
              <el-button type="primary" @click="SaveCloseroom">保存</el-button>
         </span>
      </el-dialog>

   </div>
</template>

<script>
import { getRoomCalendar , changPmsRoomState , addCloseRooms , delCloseRoomsIds , getRoomPriceList } from '@/api/pms/roomCalendar'
import { getDict , dateFactory , search_substr_pos , getDuplicate , getDaySequence } from '@/common/js/common'
import { mapState } from "vuex"
import AddAtrialstate from "../../../components/local/atrialState/AddAtrialstate";
import OrderDetails from "../../../components/local/atrialState/OrderDetails";
export default {
   name: "calendar",
   components: {
      AddAtrialstate ,
      OrderDetails
   },
   data() {
      return {
         that:this,
         maxHeight:0,
         Maps:new Map(),
         notAllowed:false,          // 页面事件是否为禁用状态

         screenBool:false,          // 是否显示筛选框
         isIndeterminate: true,
         checkAll: false,           // 是否全选图标
         checkedRoomType: [],       // 选中值
         roomTypeTree: [],          // 房型id集合

         dateRange:'.',             // 日期区间
         beginTime:'',              // 开始日期
         roomTypeIds:[],            // 房型id集合

         header:[],                 // 表头
         tableData: [],             // room 房号
         reserveMap:{},             // 已有订单状态
         roomStateMap:{},           // 关房状态

         reserveMapBool:false,      // 点击时 已有订单状态不选中
         roomStateMapBool:false,    // 点击时 关房状态不选中
         mouseenterColBool:false,   // 悬停时 显示cell
         mouseenterColBoolKey:'',

         arrkeys:[],                // 选中cell的集合
         cancelCloseroomsArr:[],    // 选中取消关房的keys集合
         cancelCloseroomsIds:[],    // 选中取消关房的ids集合
         MapArr:[],                 // 已有订单状态和关房的keys集合

         checkInState:'NOT_CHECKIN',// 新增 NOT_CHECKIN / 直接入住 CHECKIN
         contextMenuData: {
            menuName: "demo",
            axis: {x: null, y: null},
            // fnHandler绑定事件  btnName菜单名称
            menulists: [{fnHandler: "AddroomMenu", btnName: "新增"}, {fnHandler: "CloseroomMenu", btnName: "关房"},{fnHandler: "CancelroomMenu", btnName: '取消'}],
         },

         closeroomBool:false,       // 是否显示 关房弹窗
         closeroomRemark:'',        // 关房备注

         SelectColInfo:[],     // 第一次点击房号时的当前数据行
         SelectColId:[],       // 第一次点击房号时的房号id
         // 新增编辑 预订抽屉
         drawerBool:false,          // 是否显示 新增预订单 抽屉
         currentData:[],            // 当前房间号数据

         activeNameBool:true,      // 全日房可-显示选中.selectCol 钟点房-不可显示.selectCol

         // 订单详情 抽屉
         orderDetailsBool:false,
         bookId:'',                 // 订单id
         currentId:'',              // 子订单id
      };
   },
   mounted() {
      this.$nextTick(() => { this.maxHeight = window.innerHeight - 240;})
      getDict(["back-money-type","order-source",'payment-method','income','certificate-type'],false)
      this.handleToday()
      this.getRoomTypeTree()
   },
   watch: {
      hotelInfo(newVal,oldVal) {
         if(oldVal && newVal.id !== oldVal.id){
            this.handleToday()
            this.getRoomTypeTree()
         }
      }
   },
   computed:{
      ...mapState(['dictData','hotelInfo']),
   },
   methods: {
      // 获取 房型roomTypeTree
      getRoomTypeTree(){
         getRoomPriceList({ hotelId:this.hotelInfo.id }).then(res=>{
            if(res.success){
               this.roomTypeTree = []
               // res.records.forEach(item=>{ this.roomTypeTree.push({ label:item.id , value:item.roomTypeName }) })
               res.records.forEach(item=>{
                  this.roomTypeTree.push(item.id )
                  this.Maps.set(item.id,item.roomTypeName)
               })
            }
         })
      },
      // 多选框 开/关
      screenSwitch(state){
         //开
         if(state === 'open'){
            if(!this.screenBool) {
               this.screenBool = true
               this.notAllowed = true
            }
            // 关
         }else if(this.screenBool){
            this.screenBool = false
            this.notAllowed = false
            this.getRoomCalendars() // 筛选 roomTypeIds 房型id
         }
      },
      // 多选框 全选
      handleCheckAllChange(val) {
         this.checkedRoomType = val ? this.roomTypeTree : [];
         this.isIndeterminate = false;
      },
      // 多选框 多选
      handleCheckedCitiesChange(value) {
         this.checkAll = value.length === this.roomTypeTree.length;
         this.isIndeterminate = value.length > 0 && value.length < this.roomTypeTree.length;
      },
      // 多选框 确定
      confirmCheck(){
         // 当无选中值时，查询出所有房型数据
         this.roomTypeIds = this.checkedRoomType
         this.screenSwitch()
      },

      // 房号 悬停移入
      mouseenterRoom(event,row){
         let newDom = event.currentTarget
         if(row.state === 'NOT_CLEAN_ROOM') newDom.classList.remove('cleanRoom')
         let roomDiv = document.createElement("div")
         newDom.innerText = ''
         newDom.innerHTML = `<div style="border: 2px solid #409eff;width: 100% ; height:68px">转为${row.state === 'NOT_CLEAN_ROOM' ? '净房' : '脏房'}</div>`;
         newDom.appendChild(roomDiv)
      },
      // 房号 悬停移除
      mouseleaveRoom(event,row){
         let newDom = event.currentTarget
         if(row.state === 'NOT_CLEAN_ROOM') newDom.classList.add('cleanRoom')
         newDom.innerText = row.roomNo
      },
      // 点击修改 脏/净房
      handleSelectRoom(item){
         let param = {
            ids:[item.id],
            state:item.state === 'NOT_CLEAN_ROOM' ? 'CLEAN_ROOM' : 'NOT_CLEAN_ROOM',
         }
         changPmsRoomState(param).then(res=>{
            if(res.success){
               this.$message(`转${item.state === 'NOT_CLEAN_ROOM' ? '净房' : '脏房'}成功`);
               this.getRoomCalendars()
            }
         })
      },

      // 获取本日日期
      handleToday(){
         if(!this.notAllowed) {
            this.beginTime = dateFactory.getDistanceToday(-2,false)
            this.getRoomCalendars()
         }
      },
      // 开始日期-30天后 日期区间
      handleBeginTime(){
         this.getRoomCalendars()
      },
      // + - 前后15天日期
      changeDate(state){
         this.beginTime = dateFactory.getDistanceAppoint(this.beginTime, state, 15, false)
         this.getRoomCalendars()
      },

      // 获取房态日历
      getRoomCalendars(){
         let param = {
            beginDate:this.beginTime,
            days:15,
            hotelId: this.hotelInfo.id,
            roomTypeIds:this.roomTypeIds,
         }
         getRoomCalendar(param).then( res => {
            if (res.success) {
               let resData = res.records
               this.header = resData.header

               this.dateRange = `${this.beginTime.substr(5,10).replace(/-/, '/')}
               - ${this.header,this.header[this.header.length-1].dateStr.replace(/-/, '/')}`

               this.MapArr = []
               // 处理已有订单状态
               this.reserveMap = {}
               for (const key of Object.keys(resData.reserveMap)) {
                  let value = resData.reserveMap[key]
                  if(Object.keys(value).length !== 0 && Object.keys(value).includes('reserveList') ) {
                     this.reserveMap[key] = value
                     this.MapArr.push(key)
                  }
               }

               // 处理关房状态
               this.roomStateMap = {}
               for (const key of Object.keys(resData.roomStateMap)) {
                  let value = resData.roomStateMap[key]
                  if(Object.keys(value).length !== 0 && Object.keys(value).includes('id')){
                     this.roomStateMap[key] = value
                     this.MapArr.push(key)
                  }
               }

               this.tableData = []
               resData.roomTypeList.forEach(item=>{
                  item.rooms.forEach(items=>{
                     items.roomTypeName = item.roomTypeName     // 房型名称
                     items.roomTotalNum = item.roomTotalNum     // roomNo数量
                     item.roomPriceList.forEach(priceItem=>{
                        this.Maps.set(items.id + "_" + priceItem.dateString,priceItem.roomPrice)
                     })
                     items.roomPriceList = item.roomPriceList   // 获取 周五六不同价格情况
                     this.tableData.push(items)
                  })
               })
               // 处理房型
               let roomTypeIds = []
               this.tableData.forEach((item,index)=>{
                  item.Bool = false
                  if(this.tableData.length > index+1){
                     if(this.tableData[index+1].roomTypeId === item.roomTypeId){
                        item.Bool = true
                        if(index !== 0){
                           if(this.tableData[index-1].roomTypeId === item.roomTypeId) item.Bool = false
                        }
                        roomTypeIds.push(item.roomTypeId)
                     }else{
                        if(!roomTypeIds.includes(item.roomTypeId)) item.Bool = true
                     }
                  }else {
                     if(!roomTypeIds.includes(item.roomTypeId)) item.Bool = true
                  }
               })
            }
         })
      },

      // hover 悬停移入
      mouseEnter(row,column,cell,event){
         let newDom = event.currentTarget
         if(!this.notAllowed){
            let year = ''                  // 年
            let dateStr = column.property  // 月日
            if(column.property === '今天') dateStr = dateFactory.getDistanceToday(0,false).substring(5)
            row.roomPriceList.forEach(item=>{
               if(item.dateString.substring(5) === dateStr) year = item.dateString.substring(0,5)
            })

            if(!this.arrkeys.includes(row.id + '_'+ year+dateStr)){ // 过滤掉当前值为选中值，不进行hover显示
               if(!column.fixed){
                  this.mouseenterColBool = true
                  this.mouseenterColBoolKey = row.id + '_'+ year+dateStr
               }
            }
         }
      },
      // hover 悬停移除
      mouseLeave(){
         this.mouseenterColBool = false
         this.mouseenterColBoolKey = ''
      },
      // 设置roomId+日期为class类名，为了获取DOM
      getCellClass(data){
         let dateString = data.column.property === '今天' ? dateFactory.getDistanceToday(0,false) : this.beginTime.substring(0,4)+'-'+data.column.property
         if(dateString.indexOf('undefined') === -1) return data.row.id + '_'+ dateString
      },

      // 点击单元格
      handleSelectCol(row,column,dom){
         if(!this.notAllowed){
            let dateString = column.property === '今天' ? dateFactory.getDistanceToday(0,false) : this.beginTime.substring(0,4)+'-'+column.property
            let key = row.id + '_'+ dateString

            // 已有订单状态 显示订单详情抽屉
            if(this.reserveMapBool) return this.reserveMapBool = false
            // 关房状态
            if(this.roomStateMapBool) return this.roomStateMapBool = false

            if(!column.fixed){
               // 如果已有 取消关房，则不让选中单元格
               if(this.cancelCloseroomsArr.length) return

               // 为新增/关房存储  第一次多数据选中状态
               if(!this.drawerBool || !this.closeroomBool){
                  if(!this.SelectColId.includes(key)){
                     this.SelectColInfo.push(row)
                     this.SelectColId.push(key)
                  }else{
                     let index = this.SelectColId.indexOf(key)
                     this.SelectColInfo.splice(index,1)
                     this.SelectColId.splice(index,1)
                  }
               }

               // 处理选中值
               if(!this.drawerBool){
                  let menulists = [{fnHandler: "AddroomMenu", btnName: "新增"},{fnHandler: "CloseroomMenu", btnName: "关房"}, {fnHandler: "CancelroomMenu", btnName: '取消'}]
                  let heightCount = 152
                  if(!this.arrkeys.includes(key)) {
                     this.arrkeys.push(key)

                     let bools = this.directCheckin(this.arrkeys,row.id)
                     if(bools){
                        menulists.splice(1,0,{fnHandler: "InroomMenu", btnName: "入住"})
                        heightCount = 202
                     }
                     this.addPositionData(menulists,dom,heightCount)
                  } else {
                     this.arrkeys.splice(this.arrkeys.indexOf(key),1)

                     if(this.arrkeys.length === 0 ) return;
                     let lastKey = this.arrkeys[this.arrkeys.length-1]
                     let lastDom = document.getElementsByClassName(lastKey)[0]
                     let bools = this.directCheckin(this.arrkeys,lastKey.substring(0,lastKey.indexOf('_')))
                     // 处理
                     if(bools){
                        menulists.splice(1,0,{fnHandler: "InroomMenu", btnName: "入住"})
                        heightCount = 202
                     }
                     this.cancelPositionData(menulists,lastDom,heightCount)
                  }
               }
               // 点击移除当前(悬停显示)所有子节点
               this.mouseLeave()
               // 第n次点击调用
               if(this.drawerBool){
                  let rowItem = JSON.parse(JSON.stringify(row))
                  rowItem.dateString = dateString
                  rowItem.keys = key
                  return this.clickSelectCol("single",[rowItem],dom)
               }
            }
         }
      },

      // 是否可直接入住
      directCheckin(arrkeys,rowId){
         let roomsIdArr = []
         let roomsObj = {} // 设置相同房号id为key，数组日期为value
         arrkeys.forEach(item=>{
            let indexNum = item.indexOf("_")
            let date = item.substring(indexNum+1)
            let roomsId = item.substring(0,indexNum)
            if(!roomsIdArr.includes(roomsId)){
               roomsIdArr.push(roomsId)
               roomsObj[roomsId] = []
            }
            roomsObj[roomsId].push(date)
         })

         let bool = true
         let roomsKey = []
         let returnBools = false
         Object.keys(roomsObj).forEach(item=>{
            if(rowId === item){
               roomsObj[item].forEach(dateItem=>{
                  if(!dateFactory.compareTime(dateItem,dateFactory.getDistanceToday(-1,false))) bool = false // 不能直接入住
                  else roomsKey.push(dateItem)
               })
            }
         })
         if(bool){
            if(roomsKey.sort().includes(dateFactory.dateFormat(false))) returnBools = true
            else returnBools = false
         }
         // return returnBools
         return false
      },

      // 判断(选中/取消)房间
      clickSelectCol(state,row,dom){
         let Unchecked = true
         // vue-context-menu的新增，row为多个数据的数组，为加法
         if(state === 'multiple'){
            Unchecked = true
         } else if(state === 'single'){
            // 第n次点击选中单元格，可能是添加/取消单元格，只会是一条数据
            if(row.length === 1){
               if(!this.arrkeys.includes(row[row.length-1].keys)){
                  this.arrkeys.push(row[row.length-1].keys)

                  let menulists = [{fnHandler: "AddroomMenu", btnName: "新增"},{fnHandler: "CloseroomMenu", btnName: "关房"}, {fnHandler: "CancelroomMenu", btnName: '取消'}]
                  let heightCount = 152
                  this.checkInState = 'NOT_CHECKIN'
                  let bools = this.directCheckin(this.arrkeys,row[0].id)
                  if(bools){
                     menulists.splice(1,0,{fnHandler: "InroomMenu", btnName: "入住"})
                     heightCount = 202
                     this.checkInState = 'CHECKIN'
                  }
                  this.addPositionData(menulists,dom,heightCount)
                  Unchecked = true
               } else {
                  Unchecked = false
               }
            }
         }
         // 判断选中+还是取消-
         if(Unchecked) this.selectAddDel('+',row)
         else this.selectAddDel('-',row)
      },
      // 选中/取消 单元格
      selectAddDel(state,row){
         row.forEach(item=>{
            if(state === '+'){
               this.currentData.push({
                  startTime:item.dateString,      // 入住日期
                  endTime:item.dateString,
                  duration:1,                     // 天数
                  rooms:[item.roomTypeId,item.id],// cascader默认选中值 [房型id,房间号id]
                  price:item.roomPrice,           // 价格
                  keys:item.keys,
                  roomRate:item.roomPrice,        // 总价 (单价*天数)
               })
               this.getEnumerate(this.currentData)
            }else{
               this.getEnumerate(this.currentData,row) // 第二个参数是为了处理 取消
            }
            this.combineCheckinRoom(row)
         })
      },
      // 获取枚举原格式
      getEnumerate(getEnumerate,row){
         getEnumerate.forEach(item=>{
            let indexNum = search_substr_pos(item.keys,'_')
            // 如果 有连续日的数据
            if(item.duration !== 1){
               let roomsKey = item.keys.substring(0,indexNum[0]+1)
               let dateRange = dateFactory.getDateRange(item.startTime,item.endTime)
               // 设置第一条数据
               item.duration = 1
               item.endTime = item.startTime
               item.price = this.Maps.get(item.rooms[0]+'_'+item.startTime)
               item.roomRate = this.Maps.get(item.rooms[0]+'_'+item.startTime)
               delete item.discount
               dateRange.forEach((dateRangeItem,dateRangeIndex)=>{
                  if(dateRangeIndex !== 0){
                     getEnumerate.push({
                        startTime:dateRangeItem,     // 入住日期
                        endTime:dateRangeItem,
                        duration:1,                  // 天数
                        rooms:item.rooms,            // cascader默认选中值 [房型id,房间号id]
                        price:this.Maps.get(item.rooms[0]+'_'+dateRangeItem),           // 价格
                        keys:roomsKey+dateRangeItem,
                        roomRate: this.Maps.get(item.rooms[0]+'_'+dateRangeItem),       // 总价 房型id+日期 映射房型价格
                     })
                  }
               })
            }
         })
         // 减法处理
         if(row){
            row.forEach(item=>{
               getEnumerate.forEach((items,indexs)=>{

                  if(items.keys === item.keys) {
                     getEnumerate.splice(indexs,1)

                     let menulists = [{fnHandler: "AddroomMenu", btnName: "新增"},{fnHandler: "CloseroomMenu", btnName: "关房"}, {fnHandler: "CancelroomMenu", btnName: '取消'}]
                     let heightCount = 152

                     this.arrkeys.splice(this.arrkeys.indexOf(item.keys),1)
                     if(this.arrkeys.length === 0 ) return;
                     let lastKey = this.arrkeys[this.arrkeys.length-1]
                     let lastDom = document.getElementsByClassName(lastKey)[0]
                     let bools = this.directCheckin(this.arrkeys,lastKey.substring(0,lastKey.indexOf('_')))
                     this.checkInState = 'NOT_CHECKIN'
                     if(bools){
                        menulists.splice(1,0,{fnHandler: "InroomMenu", btnName: "入住"})
                        heightCount = 202
                        this.checkInState = 'CHECKIN'
                     }
                     this.cancelPositionData(menulists,lastDom,heightCount)
                  }
               })
            })
         }
         sessionStorage.setItem('currentData',JSON.stringify(getEnumerate))
      },
      // 整合 当房号一致，日期可以为区间时进行合并
      combineCheckinRoom(){
         let sessionCurrentData = JSON.parse(sessionStorage.getItem('currentData'))

         let arrkeysSubt = []  // 截取keys中 roomTypeId + 房号
         sessionCurrentData.forEach(item=>{
            let indexNum = item.keys.indexOf('_')
            item.price = this.Maps.get(item.keys)
            item.roomRate = this.Maps.get(item.keys) // 获取房费总价
            arrkeysSubt.push(item.keys.substring(0,indexNum))
         })

         // 获取数组中重复值的对应下标
         let DuplicateArr = getDuplicate(arrkeysSubt)

         // 如果无连续重复值，则数据总价roomRate为当日价格
         if(DuplicateArr.length === 0) { return }
         this.currentData.forEach(item=>{
            item.price = this.Maps.get(item.keys)
            item.roomRate = this.Maps.get(item.keys) // 获取房费总价
            delete item.discount
         })

         let roomsArr = []     // 获取重复 房型id+房号，需要做去重
         DuplicateArr.forEach((item,index)=>{
            // console.log(item,item.split(","),`=====================第${index}组`)
            let daysArr = []   // 当n条数据房号一致时 获取n条数据日期
            item.split(',').forEach(itemIndex=>{
               let keys = sessionCurrentData[itemIndex].keys
               let indexNum = search_substr_pos(keys,'_')
               if(!roomsArr.includes(keys.substring(0,indexNum[0]))) roomsArr.push(keys.substring(0,indexNum[0]))// keys截取 房型房号,条件去重
               daysArr.push(keys.substring(indexNum[0]+1)) // keys截取 年月日keys
            })

            // 检测数组日期 是否为连续日
            let getDaySequenceData = getDaySequence(daysArr)
            // 进行合并
            if(getDaySequenceData.length != 0){
               getDaySequenceData.forEach(itemDayarr=>{   // 可以合并的日期数组
                  let itemDayarrs = itemDayarr.split(',')
                  let roomRate = 0
                  itemDayarrs.forEach(itemDay =>{
                     roomRate = roomRate + this.Maps.get(roomsArr[index]+'_'+itemDay) // 获取房费总价
                  })
                  itemDayarrs.forEach( (itemDay,itemIndex) =>{   // 单个日期
                     this.currentData.forEach((currentItem,currentIndex)=>{
                        if(currentItem.keys === roomsArr[index]+ "_" +itemDay){
                           // 第一个连续日期不删减，房间号添加
                           if(!itemIndex){
                              currentItem.duration = itemDayarrs.length
                              currentItem.startTime = itemDayarrs[0]
                              currentItem.endTime = itemDayarrs[itemDayarrs.length-1]
                              currentItem.roomRate = roomRate
                              delete currentItem.discount
                           } else{
                              // 第n个联系日期删减
                              this.currentData.splice(currentIndex,1)
                           }
                        }
                     })
                  })
               })
            }
         })
      },

      handleReserveMap(){ this.reserveMapBool = true },

      // 点击已有订单状态
      orderDetails(row,bool){
         this.reserveMapBool = true
         this.closeDrawer(false)
         this.orderDetailsBool = true
         this.bookId = row.bookId
         if(bool) this.currentId = row.id
         else this.currentId = row.reserveList[0].id
      },
      // 关闭 订单详情 抽屉
      closeOrderDetails(val,bookId){
         this.orderDetailsBool = val
         this.bookId = bookId
         this.currentId = ''
      },

      // 新增 vue-context-menu
      AddroomMenu(checkInState){
         this.checkInState = checkInState
         this.closeOrderDetails(false,'') // 预订单抽屉true 则详情抽屉false
         if(this.drawerBool) return
         if(this.SelectColInfo.length !== 0){
            let rowItem = JSON.parse(JSON.stringify(this.SelectColInfo))
            rowItem.forEach((item,index)=>{
               this.SelectColId.forEach((items,indexs)=>{
                  if(index === indexs) {
                     let indexNum = items.indexOf("_")
                     item.dateString = items.substring(indexNum+1) // 日期
                     item.keys = items                             // keys
                  }
               })
            })
            this.clickSelectCol('multiple',rowItem) // SelectColIdItem
         }
         this.drawerBool = true
         // this.SelectColInfo = []
         // this.SelectColId = []
      },

      // 关房 vue-context-menu
      CloseroomMenu(){
         this.currentData = []
         this.closeroomBool = true
      },
      // 点击关房状态cell
      closeroomsDetails(keys,row){
         this.roomStateMapBool = true
         let dom = document.getElementsByClassName(keys)[0]
         if(!this.cancelCloseroomsArr.includes(keys)){
            this.cancelCloseroomsArr.push(keys)
            this.cancelCloseroomsIds.push(row.id)
            dom.classList.add('closeRoomsBorder')
            this.addPositionData([{fnHandler: "OpenroomMenu", btnName: "开房"},{fnHandler: "CancelroomMenu", btnName: '取消'}],dom,103)
         } else {
            this.cancelCloseroomsArr.splice(this.cancelCloseroomsArr.indexOf(keys),1)
            this.cancelCloseroomsIds.splice(this.cancelCloseroomsIds.indexOf(row.id),1)
            dom.classList.remove('closeRoomsBorder')
            let lastDom = document.getElementsByClassName(this.cancelCloseroomsArr[this.cancelCloseroomsArr.length-1])[0]
            this.cancelPositionData(this.cancelCloseroomsArr,lastDom,103)
         }
      },
      // 完成 关房
      SaveCloseroom(){
         let roomStateList = []
         if(this.SelectColInfo.length !== 0){
            this.SelectColInfo.forEach((item,index)=>{
               let arr = this.SelectColId[index]
               // 获取重复房号的 日期和对应下标
               let state = []
               if(roomStateList.length){
                  roomStateList.forEach((items,indexs)=>{
                     if(items.roomId === arr.substring(0,arr.indexOf('_'))){
                        state = [arr.substring(arr.indexOf('_')+1),indexs]
                     }
                  })
               }
               // 无重复房号时 添加房号id、房型id、日期
               if(!state.length){
                  roomStateList.push({
                     roomId: arr.substring(0,arr.indexOf('_')),
                     roomTypeId:item.roomTypeId,
                     roomDateList:[arr.substring(arr.indexOf('_')+1)]
                  })
               }
               // 有重复房号时 只添加日期
               else roomStateList[state[1]].roomDateList.push(state[0])
            })
         }
         let param = {
            hotelId:this.hotelInfo.id,
            companyId:this.hotelInfo.storeId,
            roomStateList:roomStateList,
            remark:this.closeroomRemark,
         }
         addCloseRooms(param).then(res => {
            if(res.success){
               this.closeroom()
               this.getRoomCalendars()
               this.$message.success("关房成功")
            }
         })
      },
      // 开房 vue-context-menu
      OpenroomMenu(){
         delCloseRoomsIds(this.cancelCloseroomsIds.join(',')).then(res=>{
            if(res.success){
               // 清除样式和子节点
               this.cancelCloseroomsArr.forEach(item=>{
                  document.getElementsByClassName(item)[0].classList.remove('closeRoomsBorder')
               })
               this.cancelCloseroomsIds = []
               this.cancelCloseroomsArr = []
               this.getRoomCalendars()
               this.$message.success('开房成功')
            }
         })
      },

      // 取消房间 vue-context-menu
      CancelroomMenu (){
         this.closeDrawer(this.drawerBool)
      },
      // 取消 关房弹窗 清空数据
      closeroom(){
         this.closeroomBool = false
         this.SelectColInfo = []
         this.SelectColId = []
         this.arrkeys = []
         this.closeroomRemark = ''
      },

      // 关闭预订单 抽屉 //  this.notAllowed = false  this.activeNameBool = true
      closeDrawer(val){
         this.currentData = []
         this.arrkeys = []
         this.drawerBool = val
         if(!this.drawerBool) this.notAllowed = false

         this.SelectColInfo = []
         this.SelectColId = []

         // 移除已选中的关房状态
         this.cancelCloseroomsArr.forEach(item=>{
            document.getElementsByClassName(item)[0].classList.remove('closeRoomsBorder')
         })
         this.cancelCloseroomsArr = []
         // this.cancelCloseroomsIds = []

         this.activeNameBool = true
      },

      // 日期/房型房号 发生改变时
      spliceCol(arrkeys){
         this.arrkeys.splice(0,this.arrkeys.length,...arrkeys)
      },
      // 计数器加减+-天数时
      setArrkeys(symbol,keys){
         if(symbol === '+') this.arrkeys.push(keys)
         else this.arrkeys.splice(this.arrkeys.indexOf(keys),1)
      },
      // 当预订单选择为钟点房时
      changeNotAllowed(bool){
         this.notAllowed = bool
         this.activeNameBool = !this.notAllowed
      },


      // 添加选中值，右侧菜单坐标跟随
      addPositionData(arr,dom,heightCount){
         this.contextMenuData.menulists = arr
         // dom.preventDefault();
         // // 获取元素 右中角坐标
         let positionData = dom.getBoundingClientRect()
         let x = positionData.x + positionData.width;
         let y = positionData.y + (positionData.height/2) - (heightCount/2);
         this.contextMenuData.axis = { x, y }
      },
      // 取消选中值，右侧菜单坐标跟随最后一个添加的元素坐标 document.getElementsByClassName(arr[arr.length-1])[0]
      cancelPositionData(arr,lastDom,heightCount){
         if(arr.length === 0 ) return;
         if(arr[arr.length-1] == '') return;
         // let lastDom = document.getElementsByClassName(arr[arr.length-1])[0]
         // console.log('oldDom',oldDOM)
         this.contextMenuData.menulists = arr
         let positionData = lastDom.getBoundingClientRect()
         let x = positionData.x + positionData.width;
         let y = positionData.y + (positionData.height/2) - (heightCount/2);
         this.contextMenuData.axis = { x, y }
      },
   },
   filters:{
      // 房型id
      // filterRoomtypeTree(val,that){
      //    return that.maps.get(val)
      // },
      // 渠道
      filterChannel(val,that){
         if (val && that.dictData.hasOwnProperty('order-source-list')){
            if(that.dictData['order-source-list'].length){
               that.dictData['order-source-list'].forEach(item=>{
                  if(item.value === val)  val = item.label
               })
               return val
            }
         }
      },
      filterCheckInState(val){
         switch (val){
            case 'NOT_CHECKIN' :
               return '已预订'
            case 'CHECKIN' :
               return '已入住'
            case 'CHECKOUT' :
               return '已退房'
            case 'CANCEL' :
               return '已取消'
            default :
               return '-'
         }
      },
      filterRoomtypeTree(val,that){
         return that.maps.get(val)
      },
   }
};
</script>

<style lang="scss" >
$border-grey:whitesmoke;
.text-center{ text-align: center; }
.grey{ color:#999; }
.red{ color: red!important; }
.blue{ color: #0c7ffd!important; }
ul li{ list-style-type: none; }
.calendarBox{
   display: flex;
   // 筛选框
   .roomType-screenBox{
      display: inline-block;
      width:15%;
      border: 1px solid $border-grey;
      margin-right: 5px;
      position: relative;
      height: 60vh;
      .header{
         color: #999;
         display: flex;
         justify-content: space-between;
         padding: 10px 10px;
      }
      .main{
         padding: 10px 10px;
         margin-bottom: 50px;
         .el-checkbox{
            display: block;
         }
      }
      .footer{
         width: 100%;
         position: absolute;
         bottom: 0;
         text-align: right;
         padding: 10px 0;
      }
   }

   .el-table__body{
      .el-table__row{
         .el-table__cell{
            .cell{ padding: 0 }
         }
      }
   }
   .el-table{
      .el-table__fixed{
         .el-table__fixed-header-wrapper{
            .el-table__header{
               th:first-child{
                  padding:0!important;
                  .cell{
                     padding:0;
                     display: flex;
                     flex-direction: column;
                     // .dateRangebox{ }
                     .switchBox{
                        height: 52px;
                        line-height: 43px;
                        border-top:1px solid #EBEEF5;
                     }
                  }
               }
            }
         }
      }
   }

   .dayTimebox{
      .el-input__inner{
         border: none!important;
      }
   }
   .butToday{
      background-color: #0c7ffd;
      color: $border-grey;
      border-radius: 4px;
      padding: 2px 5px;
   }

   // 房号 悬停
   .cleanRoom{
      background-color: #cccccc;
   }
   .cleanRoom::before{
      content: '';
      background-image: url("../../../assets/squalor.png");
      background-size: cover;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
   }

   // 悬停样式
   .mouseenter-box{
      color: #999;
      font-size: 12px;
      height: 65px;
      line-height: 22px;
   }
   // 选中样式
   .selectCol-box{ height: 68px; background-color: #A2CFFF;border: 2px solid white; }
   .selectCol-box::before{
      content: '';
      width: 100%;
      height: 60px;
      background: url("../../../assets/pms-select-room.png") 0 0 no-repeat ;
      position: absolute;
      left: 28px;
      top: 14px;
   }
   .orderState-box{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 64px;
      padding-right: 5px;
      margin: 2px;
      text-align: left;
      font-size: 14px;
      color: black;
      outline: none;
   }
   .orderState-or{ background-color:  #FFF2E1; }
   .orderState-bl{ background-color:  #ECF5FF; }
   .orderState-gr{ background-color:  #EFEFEF; }
   // 预定
   .orderState-or::before{
      width: 6px;
      height: 64px;
      content: '';
      float: left;
      background-color: #FF9100;
      margin-right: 2px;
   }
   // 入住
   .orderState-bl::before{
      width: 5px;
      height: 64px;
      content: '';
      float: left;
      background-color: #A2CFFF ;
   }
   // 退房
   .orderState-gr::before{
      width: 5px;
      height: 64px;
      content: '';
      float: left;
      background-color: #585858;
   }
   // 钟点房
   .arrearsTime{
      width: 18px;
      height: 18px;
      position: relative;
      display: inline-block;
   }
   .arrearsTime::after{
      content: '';
      background-image: url("../../../assets/pms-hour-room.png");
      background-size: cover;
      background-color: white;
      border-radius: 17px;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0px;
      top: 5px;
   }
   // 是否欠款
   .arrears{
      width: 18px;
      height: 18px;
      position: relative;
      display: inline-block;
   }
   .arrears::after{
      content: '';
      background-image: url("../../../assets/pms-arrears.png");
      background-size: cover;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0px;
      top: 5px;
   }
   // 关房
   .closeRooms-box{
      position: absolute;
      top: 0;
      z-index: 2;
      width: calc( 100% - 4px );
      height: 64px;
      margin: 2px;
      text-align: center;
      font-size: 14px;
      background-color: #EFEFEF;
      color: #585858;
   }
   .closeRooms-box::before{
      content: '';
      background-image: url("../../../assets/pms-close-room.png");
      background-size: cover;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 6px;
      left: calc( 50% - 15px);
   }
   .closeRoomsBorder::before{
      content: "√";
      position: absolute;
      right: 5px;
      top: 2px;
      z-index: 3;
      font-weight: 700;
      width: 18px;
      height: 18px;
      line-height: 18px;
      display: inline-block;
      color: white;
      background-color: #0c7ffd;
      font-size: 10px;
      margin-top: 3px;
      border-radius: 2px;
   }

   // menu
   .context-menu{
      width: 60px;
      height: auto;
      border: 1px solid #4d4d4d;
      //box-shadow: 0px 3px 7px 0px rgba(150, 150, 150, 0.08);
      border-radius: 5px;
      background-color: #4d4d4d!important;
      font-size:15px;
      .context-menu-list{
         background-color: #4d4d4d!important;
         padding: 10px 0px;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: white;
         .nav-name-right{
            margin: 0;
            text-align: center;
            font-size:15px;
         }
      }
      .context-menu-list :hover{ color: #bbbbbb;}
   }
   .context-menu::before{
      content: '';
      position: absolute;
      top: 50%;
      left: -6px;
      z-index: 2;
      border:5px #4d4d4d  solid;
      border-color:transparent #4d4d4d  transparent transparent;
      border-left:none;
      margin-bottom: 10px;
   }
}
</style>
<style lang="scss">
// 已有状态 弹出框
.popver{
   max-height: 70vh;
   //overflow-y: auto;
   .popver-item{
      display:flex;
      align-items:center;
      padding:4px 0;
      position: relative;
   }
   .border-b-dashed{ border-bottom:1px dashed #E1E1E1!important;}
}

</style>